<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    style="padding-top: 0"
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        md12
        style="padding-top: 0"
      >
        <v-card-title
          style="padding: 0"
        >
          <v-flex
            xs12
            md9
            style="padding: 0"
          >
            <v-btn
              class="mx-3 font-weight-medium"
              color="green"
              @click.prevent="showAddMemberDialog">
              <v-icon>mdi-account-multiple-plus-outline
              </v-icon>
              <span class="mx-2 font-weight-medium">添加用户</span>
            </v-btn>
          </v-flex>
          <v-flex
            xs12
            md3
            style="padding: 0"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-flex>
        </v-card-title>
        <material-card
          color="green"
          style="margin-top: 30px"
        >
          <div
            slot="header"
            class="d-flex">
            <span>
              <div class="title mb-2">
                用户列表
              </div>
            </span>
            <span
              style="text-align:right;margin-top: -10px">
              <div>
                <span style="margin-right:20px;">
                  <v-btn
                    small
                    round
                    class="mx-0 font-weight-medium"
                    color="transparent"
                    @click="memberList"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </span>
              </div>
            </span>
          </div>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            class="elevation-3 text-xs-left"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <div
                class="subheading font-weight-regular text-success text--darken-3 text-xs-left"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.id }}</td>
              <td class="font-weight-regular">{{ item.username }}</td>
              <td>{{ item.nickname }}</td>
              <td>{{ $getDateTimeString(item.createdAt) }}</td>
              <td>
                <v-btn
                  slot="activator"
                  class="v-btn--small"
                  color="red"
                  icon
                  @click.prevent="showMemberInfoDialog(item)"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </td>
              <td>
                <div v-if="item.enabled">
                  <v-btn
                    slot="activator"
                    class="v-btn--small"
                    color="green"
                    icon
                    @click.prevent="enabledUser(item.id, false)"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn
                    slot="activator"
                    class="v-btn--small"
                    color="red"
                    icon
                    @click.prevent="enabledUser(item.id, true)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </td>
            </template>

          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>

    <!--弹出新增成员填写信息 S-->
    <v-dialog
      v-model="showHide.MemberDialog"
      max-width="500"
      persistent
      @keydown.esc="showHide.MemberDialog = false"
    >
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green"
          >
            <div
              slot="header"
            >
              <div class="mb-2 d-flex">
                <span class="title font-weight-bold mb-2">添加用户信息</span>
                <span class="text-xs-right">
                  <v-icon
                    size = "24"
                    @click = "closeAddMemberDialog"
                  >mdi-close-circle</v-icon>
                </span>
              </div>
            </div>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                v-model="memberDialog.username"
                label="用户名"
                clearable
                @keyup.enter="addMember"
              />
              <v-text-field
                v-model="memberDialog.password"
                :append-icon="showHide.addMemberPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showHide.addMemberPassword ? 'text' : 'password'"
                hint="密码最少6位"
                label="密码"
                clearable
                @keyup.enter="addMember"
                @click:append="showHide.addMemberPassword = !showHide.addMemberPassword"
              />
              <v-text-field
                v-model="memberDialog.nickname"
                label="别名"
                clearable
                @keyup.enter="addMember"
              />
              <v-text-field
                v-model="memberDialog.company"
                label="公司"
                clearable
                @keyup.enter="addMember"
              />
            </v-flex>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            xs12
            text-xs-right
            style="margin-right:20px"
          >
            <v-btn
              class="mx-1 font-weight-light"
              color="success"
              @click="addMember"
            >
              确认添加
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出新增成员填写信息 E-->

    <!--弹出成员密码修改信息 S-->
    <v-dialog
      v-model="showHide.MemberPasswordDialog"
      max-width="500"
      persistent
      @keydown.esc="showHide.MemberPasswordDialog = false"
    >
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green"
          >
            <div
              slot="header"
            >
              <div class="mb-2 d-flex">
                <span class="title font-weight-bold mb-2">修改用户信息</span>
                <span class="text-xs-right">
                  <v-icon
                    size = "24"
                    @click = "closeMemberInfoDialog"
                  >mdi-close-circle</v-icon>
                </span>
              </div>
            </div>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                v-model="memberModifyInfo.username"
                label="成员名"
                readonly
                @keyup.enter="modifyMemberInfo"
              />
              <v-text-field
                v-model="memberModifyInfo.password"
                :append-icon="showHide.MemberPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showHide.MemberPassword ? 'text' : 'password'"
                hint="密码最少6位"
                label="新密码"
                clearable
                @click:append="showHide.MemberPassword = !showHide.MemberPassword"
                @keyup.enter="modifyMemberInfo"
              />
              <v-text-field
                v-model="memberModifyInfo.nickname"
                label="别名"
                @keyup.enter="modifyMemberInfo"
              />
              <v-text-field
                v-model="memberModifyInfo.company"
                label="公司名"
                @keyup.enter="modifyMemberInfo"
              />
            </v-flex>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            xs12
            text-xs-right
            style="margin-right:20px"
          >
            <v-btn
              class="mx-1 font-weight-light"
              color="success"
              @click="modifyMemberInfo"
            >
              确认修改
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出成员密码修改信息 E-->

  </v-container>
</template>

<script>
export default {
  directives: {
    focus (el) {
      let dialogusername = document.getElementById('dialogusername')
      if (dialogusername !== undefined && dialogusername != null) {
        dialogusername.focus()
      }
    }
  },
  data: () => ({
    search: '',
    showHide: {
      MemberDialog: false,
      MemberPasswordDialog: false,
      addMemberPassword: false,
      MemberPassword: false
    },
    memberDialog: {
      username: '',
      password: '',
      nickname: '',
      company: ''
    },
    memberModifyInfo: {
      username: '',
      password: '',
      nickname: '',
      company: '',
      id: ''
    },
    headers: [
      { sortable: false, text: 'ID', value: 'id' },
      { sortable: false, text: '用户名', value: 'username' },
      { sortable: false, text: '别名', value: 'nickname' },
      { sortable: false, text: '创建时间', value: 'createdAt' },
      { sortable: false, text: '修改', value: '' },
      { sortable: false, text: '可用', value: '' }
    ],
    items: [
    ]
  }),
  created () {
    this.memberList()
  },
  methods: {
    memberList () {
      this.items = []
      let config = this.$authHeaders()
      config.params =
        config.params = {
          size: 100,
          page: 0
        }
      this.$http
        .get(this.$store.state.app.url + '/admin/ctrl/userList', config)
        .then(res => {
          let data = res.data
          if (data.errCode === 0) {
            let result = data.result
            this.items = result.content
            console.log(result)
          } else {
            console.log(data.errMsg)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    showAddMemberDialog () {
      this.showHide.MemberDialog = true
      this.memberDialog.username = ''
      this.memberDialog.password = ''
      this.memberDialog.nickname = ''
      this.memberDialog.company = ''
    },
    closeAddMemberDialog () {
      this.showHide.MemberDialog = false
    },
    addMember () {
      if (this.memberDialog.username === '') {
        this.$snackbar.warning('请输入用户名')
        return
      }
      if (this.memberDialog.password === '') {
        this.$snackbar.warning('请输入密码')
        return
      }
      if (this.memberDialog.nickname === '') {
        this.$snackbar.warning('请输入别名')
        return
      }
      if (this.memberDialog.company === '') {
        this.$snackbar.warning('请输入公司名')
        return
      }
      let data = {
        username: this.memberDialog.username,
        password: this.memberDialog.password,
        nickname: this.memberDialog.nickname,
        company: this.memberDialog.company
      }
      this.$http
        .post(this.$store.state.app.url + '/admin/ctrl/addAdminUser', data, this.$authHeaders())
        .then(res => {
          let data = res.data
          if (data.errCode === 0) {
            // console.log('addAdminUser() ', data)
            // let result = data.result
            // console.log(result)
            // console.log(this.items)
            // this.items.push(result)
            // console.log(this.items)
            this.$snackbar.info('添加用户成功')
            this.closeAddMemberDialog()
            this.memberList()
          } else {
            console.log(data.errMsg)
            this.$snackbar.error(data.errMsg)
          }
        }, (error) => {
          this.$httpCatch(error)
        })
    },
    enabledUser (id, enabled) {
      console.log('enabledUser()', id, enabled)
    },
    showMemberInfoDialog (item) {
      console.log('showMemberInfoDialog()', item.id)
      // this.showHide.MemberPasswordDialog = true
      // this.memberModifyInfo.password = ''
      // this.memberModifyInfo.username = item.username
      // this.memberModifyInfo.nickname = item.nickname
      // this.memberModifyInfo.company = item.company
      // this.memberModifyInfo.id = item.id
    },
    closeMemberInfoDialog () {
      this.showHide.MemberPasswordDialog = false
    },
    modifyMemberInfo () {
      if (this.memberModifyInfo.password === '') {
        this.$snackbar.warning('请输入密码')
        return
      }
      if (this.memberModifyInfo.nickname === '') {
        this.$snackbar.warning('请输入别名')
        return
      }
      if (this.memberModifyInfo.company === '') {
        this.$snackbar.warning('请输入公司名')
        return
      }
      let data = {
        password: this.memberModifyInfo.password,
        nickname: this.memberModifyInfo.nickname,
        company: this.memberModifyInfo.company
      }
      this.$http
        .post(this.$store.state.app.url + '/admin/ctrl/modifyAdminUser', data, this.$authHeaders())
        .then(res => {
          let data = res.data
          if (data.errCode === 0) {
            console.log('modifyAdminUser() ', data)
            let result = data.result
            console.log(result)
            this.$snackbar.info('修改用户信息成功')
            this.closeMemberInfoDialog()
            this.memberList()
          } else {
            console.log(data.errMsg)
            this.$snackbar.error(data.errMsg)
          }
        }, (error) => {
          this.$httpCatch(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
